<template>
    <div>
        <v-dialog v-model="dialog"
                  persistent
                  width="500">
            <v-card>
                <v-card-title class="headline grey lighten-2"
                              primary-title>
                    Präsent hinzufügen
                </v-card-title>
                <v-card-text class="mt-2">
                    <v-text-field v-model="praesent.name"
                                  label="Name"
                                  required
                                  filled
                                  ></v-text-field>
                    <v-textarea v-model="praesent.beschreibung"
                                label="Beschreibung"
                                required
                                filled></v-textarea>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary"
                           dark
                           @click="createPraesent">
                        Hinzufügen
                    </v-btn>
                    <v-btn color="secondary"
                           outlined
                           @click="updateDialog(false)">
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    export default {
        data() {
            return {
                praesent: { Name: null, AktionsId:0 }

            }
        },
        components: {

        },
        watch: {

        },
        computed: {
            ...mapState(['profile', 'praesente'])
        },
        beforeMount() {

        },
        mounted() {

        },
        props: ['dialog', 'sichtbarkeiten', 'filterPool' ,'aktionsId'],
        methods: {
            updateDialog: function (value) {
                this.$emit('update:dialog', value)

            },
            createPraesent: function () {
                this.praesent.AktionsId = this.aktionsId;
                this.$http.post('/api/Stammdaten/CreateNewPraesent', this.praesent).then(response => {
                   // this.praesent['id'] = response.data;

                    //Update in Vuex
                    //TODO: create push function in Vuex
                    var tempPraesente = this.praesente;
                    tempPraesente.push(this.praesent);

                    this.$store.dispatch('setPraesente', {
                        data: tempPraesente
                    });


                    var tempSichten = JSON.parse(JSON.stringify(this.sichtbarkeiten));
                    var res = response.data;

                    for (var i = 0; i < res.length; i++) {
                          tempSichten.push(res[i]);
                    }

                    this.$emit('update:sichtbarkeiten', tempSichten)
                    this.$emit('filterPool')
                }).catch((error) => console.log(error));
                this.updateDialog(false);

            }
        },

    }
</script>

<style scoped>
</style>
